import React from 'react'

export default function TranslateCard() {

    return (
        <div className='flex flex-col p-5 h-full justify-between'>
            <p className='text-2xl font-bold text-white'>Translation</p>
            <div className='flex flex-col justify-center items-center'>
                <img src="/images/globe.png" className='w-12 h-12 mt-5' alt="party emoji"/>
                <p className='text-white mt-5 text-center'>Help us translate Wolfer to your language!</p>
                <a href="https://r.translate.wolfer.fun/" target="_blank" rel="noreferrer" className='bg-white text-blue-800 w-full p-2 rounded-md mt-5 text-center'>I want to help!</a>
            </div>
        </div>
    )
}
