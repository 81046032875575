import React from 'react'

export default function BetaReportBugsCard(props) {
  return (
    <div className='border-orange-600 bg-orange-700 border-[1px] h-auto w-full sm:w-1/2 md:w-1/3 lg:w-1/5 shadow-md rounded-md'>
        <div className='flex flex-col p-5 h-full justify-between'>
            <p className='text-2xl font-bold text-white'>Report bugs ⚠️</p>
            <div className='flex flex-col justify-center items-center'>
                <img src="/images/alert.png" className='w-12 h-12 mt-5' alt="party emoji"/>
                <p className='text-white mt-5 text-center'>Fill out the following form to report a bug!</p>
                <button className='bg-gray-500 text-gray-800 w-full p-2 rounded-md mt-5 text-center'>Report bug</button>
            </div>
        </div>
    </div>
  )
}
