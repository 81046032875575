import React from 'react'

export default function BetaScheduleCard(props) {
  return (
    <div className='border-gray-600 bg-gray-700 border-[1px] h-full w-full sm:w-1/2 md:w-1/3 lg:w-1/5 shadow-md rounded-md'>
        <div className='flex flex-col p-5 h-full'>
            <p className='text-2xl font-bold text-white'>Schedule</p>
            <div className='flex flex-col justify-center items-center h-full w-full'>
                <img src="/images/-_-.png" alt="-_-" width="64"/>
                <p className='text-md text-white mt-2'>No beta session scheduled (yet).</p>
            </div>
        </div>
    </div>
  )
}
