import React from 'react'

export default function Presentation() {
  return (
    <div className='w-4/6 m-1 flex flex-col items-center justify-center md:flex-row md:justify-between'>
        <div className='w-full mt-5 md:mt-0 md:w-2/3 flex flex-col justify-center items-center'>
            <p className='text-4xl font-bold text-white text-center'>What is Wolfer?</p>
            <p className='text-xl font-medium text-white text-center mt-5'>Each game, you are assigned a random role with specific odds. It can be either a seer, a werewolf, a simple villager and more (soon). You are part of a team with people that have the same role category as you (werewolves, villagers...). Each role has it's own capacities and win conditions, you'll need to explore them!</p>
            <p className='text-xl font-medium text-white text-center mt-5'>The game is very simple, with its minimalist design and the easy to understand rules and roles. You can play with up to 10 friends or strangers, and even create custom games to personalize your game experience. It's up to you!</p>
            <p className='text-xl font-medium text-white text-center mt-5'>Create your own avatar with thousands of different combinations! Earn coins while leveling up or through special events to buy more outfits, backgrounds, accessories and more!</p>
        </div>
        <div className='w-full md:w-1/3 flex flex-col justify-center items-center'>
            <img src='/images/screenshots/1.png' alt='Screenshot 1' className='w-4/6'/>
        </div> 
    </div>
  )
}
