import React from 'react'
import { Link } from 'react-router-dom';

export default function Welcome() {
  const [players, setPlayers] = React.useState(0);
  React.useEffect(() => {
    fetch('https://api.wolfer.fun/user/stats')
      .then(response => response.json())
      .then(data => setPlayers(data.totalUsers));
  }, []);

  return (
    <div>
      <div className='welcome-gradient w-full h-auto'>
        <div className='flex justify-center items-center h-full'>
          <div className='flex flex-col justify-center items-center mt-10 p-2'>
            <p className='text-6xl font-bold text-white text-center pt-20'>Wolfer</p>
            <p className='text-2xl font-bold text-white text-center pt-10'>An online werewolf mobile game.</p>
            <p className='text-xl font-medium text-white text-center pt-2'>{players} players have an account so far, why not you?</p>
            <Link to="/account" className='bg-white text-black font-bold text-xl rounded-full px-10 py-3 mt-10 transition ease-in-out hover:-translate-y-1'>Play Now</Link>
          </div>
        </div>
      </div>
      <img src="/images/wave.png" alt="wave" className='w-full'/>
    </div>
  )
}
