import React from 'react'

export default function AvatarCard(props) {
    return (
        <div className='flex flex-col p-5'>
            <p className='text-2xl font-bold text-white'>Your avatar</p>
            <div className='flex flex-col justify-center items-center'>
                <img src={props.info.avatar_url} className='w-32 h-32 mt-5 rounded-full' alt="avatar"/>
            </div>
        </div>
    )
}
