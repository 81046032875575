import React from 'react'
import Copyright from '../components/Copyright'
import Navbar from '../components/Navbar'
import Monitor from './components/Monitor';

export default function Status() {
const [monitors, setMonitors] = React.useState([]);
  React.useEffect(() => {
    document.title = 'Wolfer - Service status';
    fetch('https://corsproxy.io/?https://bmonitord.fr6.xshadow.xyz/api/statuspage/1/public').then((res) => res.json()).then((res) => {
      setMonitors(res.data.monitors);
    });
  }, [])
  return (
    <div className="h-full w-full">
      <Navbar/>
      <div className='p-5 pt-[100px] flex flex-col items-center'>
        <p className='text-4xl font-bold text-white text-center'>Service status</p>
        {monitors.length === 0 ? <p className='text-center mt-10 text-white/50'>Loading...</p> : <></>}
        <div className="container px-5 py-5 mx-auto">
            <div className="flex flex-wrap -m-4">
              {monitors.map((monitor) => {
                return (<Monitor key={monitor.id} monitor={monitor} />)
              })}
            </div>
        </div>
        <a href="https://github.com/bartosz11/monitoring" className='text-gray-500 text-sm w-full text-center'>Monitoring system by Bartosz1 - github.com/bartosz11/monitoring</a>
      </div>
      <Copyright/>
    </div>
  )
}
