import React from 'react'
import { Link } from 'react-router-dom'

export default function Copyright() {
  return (
    <div className='text-white/[0.3] flex flex-col justify-center w-full p-1'>
        <div className='flex flex-row justify-center gap-2 mb-2'>
          <a href="https://discord.gg/Dk9UTppGry" target="_blank" rel="noreferrer"><img src='/images/discord.svg' alt='check' className='w-7 h-7'/></a>
          <a href="https://twitter.com/wolfergame" target="_blank" rel="noreferrer"><img src='/images/twitter.svg' alt='check' className='w-7 h-7'/></a>
          <a href="https://instagram.com/wolfergame_" target="_blank" rel="noreferrer"><img src='/images/instagram.svg' alt='check' className='w-7 h-7'/></a>
        </div>
        <p className='text-center'><Link to="/status">Service status</Link></p>
        <p className='text-center'><Link to="/tos">Terms of Service</Link> - <Link to="/privacypolicy">Privacy policy</Link></p>
        <p className='text-center'>© Wolfer - X_Shadow_ {new Date().getFullYear()} - All rights reserved</p>
    </div>
  )
}
