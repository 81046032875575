import React from 'react'
import Copyright from '../components/Copyright'
import Navbar from '../components/Navbar'

export default function PrivacyPolicy() {
  React.useEffect(() => {
    document.title = 'Wolfer - Privacy Policy'
  }, [])
  return (
    <div className="h-full w-full">
      <Navbar/>
      <div className='p-5 pt-[100px] h-screen'>
        <iframe title="Privacy Policy" src="/privacy.html" className='w-full h-full'></iframe>
      </div>
      <Copyright/>
    </div>
  )
}
