import React from 'react'
import Copyright from '../components/Copyright'
import Navbar from '../components/Navbar'
import Presentation from './components/Presentation'
import Welcome from './components/Welcome'

export default function Home() {
    React.useEffect(() => {
        document.title = 'Wolfer - An online mobile werewolf game.';
    }, [])
    return (
        <div className='h-full' style={{backgroundColor:'#2d3748'}}>
            <div className='w-full top-0 z-10 absolute'>
                <Navbar/>
            </div>
            <Welcome/>
            <div className='justify-center items-center flex flex-col mt-10'>
                <Presentation/>
            </div>
            <div className='flex flex-row justify-center items-center mt-5'>
               <Copyright/>
            </div>
        </div>
    )
}
