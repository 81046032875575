import React from 'react'
import { Link } from 'react-router-dom'
import ACCLoading from './Loading';

export default function BetaCard() {
    const [betaInfo, setBetaInfo] = React.useState({});
    
    async function populate() {
        const betaInfo = await (await fetch(`https://api.wolfer.fun/user/beta/status/${localStorage.getItem("uuid")}`)).json();
        setBetaInfo(betaInfo);
    }
    React.useEffect(() => {
        populate();
    }, [])
    if (!betaInfo.statusCode) return (<ACCLoading/>);
    if (betaInfo.statusCode === 200) {
        if (betaInfo.status === "OK") {
            return (
                <div className='flex flex-col p-5 h-full justify-between'>
                    <p className='text-2xl font-bold text-white'>Beta Access</p>
                    <div className='flex flex-col justify-center items-center'>
                        <img src="/images/tada.png" className='w-12 h-12 mt-5' alt="party emoji"/>
                        <p className='text-white mt-5 text-center'>You have access to the beta!</p>
                    </div>
                    <Link to="/beta" className='bg-white text-blue-800 w-full p-2 rounded-md mt-5 text-center'>Access the beta console</Link>
                </div>
            )
        } else {
            return (
                <div className='flex flex-col p-5 h-full justify-between'>
                    <p className='text-2xl font-bold text-white'>Beta Access</p>
                    <div className='flex flex-col justify-center items-center'>
                        <img src="/images/hourglass.png" className='w-12 h-12 mt-5' alt="party emoji"/>
                        <p className='text-white mt-5 text-center'>Waiting for the approval!</p>
                        <Link to="/beta" className='bg-white text-blue-800 w-full p-2 rounded-md mt-5 text-center'>Access the beta console</Link>
                    </div>
                </div>
            )
        }
    } else {
        return (
            <div className='flex flex-col p-5 h-full justify-between'>
                <p className='text-2xl font-bold text-white'>Beta Access</p>
                <div className='flex flex-col justify-center items-center'>
                    <img src="/images/testtube.png" className='w-12 h-12 mt-5' alt="party emoji"/>
                    <p className='text-white mt-5 text-center'>You do not have access to the beta (yet!)</p>
                    <Link to="/beta" className='bg-white text-blue-800 w-full p-2 rounded-md mt-5 text-center'>Register to the beta</Link>
                </div>
            </div>
        )
    }
    
}
