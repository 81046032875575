import React from 'react'

export default function UserInfoCard(props) {
    return (
        <div className='flex flex-col p-5'>
            <p className='text-2xl font-bold text-white'>Account Information</p>
            <p className='text-white mt-5'>ID: <span className='text-gray-400'>{localStorage.getItem('uuid')}</span></p>
            <p className='text-white mt-5'>Username: <span className='text-gray-400'>{props.info.username}</span></p>
            <p className='text-white mt-5'>Email: <span className='text-gray-400'>{localStorage.getItem('email')}</span></p>
            <p className='text-white mt-5'>Password: <span className='text-gray-400'>********</span></p>
            <p className='text-white mt-5'>Last login: <span className='text-gray-400'>{new Date(props.info.activity.last_login*1000).toUTCString()}</span></p>
            <p className='text-white mt-5'>Registered: <span className='text-gray-400'>{new Date(props.info.registered_at*1000).toUTCString()}</span></p>
            <div className='mt-5 md:mt-10 flex flex-row flex-wrap'>
                {props.info.staff === 1 ? <p className='text-white border-red-500 shadow-md border-[1px] bg-red-600 p-2 pt-1 pb-1 rounded-full'>Staff</p> : <></>}
            </div>
        </div>
    )
}
