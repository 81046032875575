import React from 'react'
import Copyright from '../components/Copyright'
import Navbar from '../components/Navbar'

export default function Err404() {
  React.useEffect(() => {
    document.title = 'Wolfer - 404'
  }, [])
  return (
    <div className="h-full w-full">
      <Navbar/>
      <div class='flex flex-col justify-between h-screen pt-[100px]'>
        <div className='p-5 h-full flex flex-col items-center justify-center w-full'>
          <p class='text-7xl sm:text-9xl text-white font-bold'>404</p>
          <p class='text-2xl sm:text-3xl text-white font-bold'>Page not found</p>
        </div>
        <Copyright/>
      </div>
    </div>
  )
}
