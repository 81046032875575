import React from 'react'
import { Link } from 'react-router-dom'

export default function Navbar() {
    return (
        <div className='w-full h-[70px] backdrop-blur-md fixed h-gradient'>
            <div className='flex flex-row justify-between items-center h-full pl-2 pr-2 md:pl-10 md:pr-10 lg:pl-20 lg:pr-20'>
                <div className='flex w-full justify-start'>
                    <Link to="/" className='flex flex-row text-white text-sm mr-2 font-medium uppercase p-2 rounded-md hover:bg-[#ffffff30]'>
                        <img src="/images/house.svg" className='w-6 h-6 inline mr-1 md:hidden visible' alt="home"/>
                        <span className="hidden md:block">Home</span>
                    </Link>
                </div>
                <div className='flex justify-center items-center h-[70px] w-auto'>
                    <img src='/images/logo.png' alt='Wolfer Logo' className='w-[50px] md:w-[60px]'/>
                    <h1 className='block text-3xl font-bold text-white'>Wolfer</h1>
                </div>
                <div className='flex w-full justify-end flex-row'>
                    <Link to="/login" className='flex flex-row text-white text-sm mr-2 font-medium uppercase p-2 rounded-md hover:bg-[#ffffff30]'>
                        <span className="hidden md:block">Account</span>
                        <img src="/images/user.svg" className='w-6 h-6 inline ml-1 md:hidden visible' alt="user"/>
                    </Link>
                </div>
            </div>
        </div>
    )
}
