import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Copyright from '../../components/Copyright';
import Navbar from '../../components/Navbar'
import BetaAwaitingReview from '../components/AwaitingReview';
import BetaPresentation from '../components/BetaPresentation';
import BetaLoading from '../components/Loading';
import BetaNoBetaSlots from '../components/NoMoreBetaSlots';
import BetaConsole from './Console';

export default function BetaIndex() {
    const [betaInfo, setBetaInfo] = React.useState({});
    
    async function populate() {
        const betaInfo = await (await fetch(`https://api.wolfer.fun/user/beta/status/${localStorage.getItem("uuid")}`)).json();
        setBetaInfo(betaInfo);
    }
    const navigation = useNavigate();
    React.useEffect(() => {
        populate();
        if (!localStorage.getItem('token')) {
            navigation('/login');
        }
        document.title = 'Beta console - Wolfer';
    }, [])
  return (
    <div>
        <Navbar/>
        <div className='flex flex-col w-full h-screen p-10 pt-[100px]'>
            <div className='flex flex-row w-full justify-between flex-wrap-reverse'>
                <div className='flex flex-col'>
                    <p className='text-4xl font-bold text-white'>Beta console</p>
                    <p className='text-white'>Welcome to the beta console! Here, you can test Wolfer before its first public version is released.</p>
                </div>
                <Link to="/account" className='bg-blue-500 mb-2 md:mb-0 text-white items-center flex w-32 font-bold p-3 rounded-md mt-5'><img src="/images/arrow-left.svg" alt="arrow pointing left" width="20" className="inline mr-1"/><span>Go back</span></Link>
            </div>
            {!betaInfo.statusCode ? <BetaLoading/> : 
                betaInfo.statusCode !== 200 ? 
                    <BetaPresentation/> : 
                betaInfo.status === "WAITING" ? 
                    <BetaAwaitingReview/> :
                betaInfo.status === "OK" ?
                    <BetaConsole status={betaInfo}/> :
                betaInfo.status === "NO_MORE_BETA_SLOTS" ?
                    <BetaNoBetaSlots/> :
                <BetaPresentation/>
            }
        </div>
    </div>
  )
}
