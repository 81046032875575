import React from 'react'

export default function BetaAwaitingReview() {
  return (
    <div className='flex flex-col w-full h-full justify-center items-center'>
        <img src="/images/hourglass.png" alt="Hourglass" width="100"/>
        <p className='text-4xl font-bold mt-2 text-white w-full text-center'>Awaiting review...</p>
        <p className='text-white w-full text-center'>Your beta application will be reviewed by a staff member soon.</p>
        <p className='text-white mt-3 w-full text-center'><img src="/images/envelope.svg" width="20" className='inline' alt="Envelope"/> You will be notified via email if this status updates.</p>

    </div> 
  )
}
