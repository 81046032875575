import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Copyright from '../components/Copyright';
import Navbar from '../components/Navbar'

export default function Recover() {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [done, setDone] = React.useState(false);
    const emailRef = React.useRef(null);
    const navigate = useNavigate();
    async function login() {
        setLoading(true);
        setError(null);
        const response = await fetch("https://api.wolfer.fun/user/mailing/resetPassword/" + emailRef.current.value, {method:"POST",headers: {'Content-Type': 'application/json',}});
        const data = await response.json();
        if (data.statusCode !== 200) {
            setError(data.message);
            setLoading(false);
            return;
        }
        setDone(true);
    }
    React.useEffect(() => {
        if (localStorage.getItem('token')) {
            navigate('/account');
        }
        document.title = "Recover account - Wolfer";
    }, [navigate]);
  return (
    <div className='bg-[#222a37] flex flex-row'>
        <Navbar/>
        <div className='bg-[#2d3748] flex flex-col w-full md:w-1/3 h-screen justify-center items-center pt-[100px]'>
            <div className='flex flex-col justify-center w-4/5'>
                <div className={`w-full bg-red-500/[0.5] rounded-lg p-4 mb-5 ${error !== null ? 'block' : 'hidden'}`}>
                    <p className='text-white'>{error}</p>
                </div>
                <p className='text-3xl font-bold text-white w-full mb-5'>Recover account</p>
                {done ? <div className="flex flex-col"><div className='w-full bg-green-500/[0.5] rounded-lg p-4'>
                        <p className='text-white'>If the provided email is connected to a wolfer account, we've just sent an email with the instructions to reset your password. Emails can take a few minutes to be sent, don't forget to check your spam folder!</p>
                    </div>
                    <Link to='/login' className='text-white underline mt-2'>Back to login</Link>
                </div> : <div>
                    <form className='flex flex-col justify-center w-full md:w-4/5' onSubmit={(e) => {e.preventDefault(); login();}}>
                        <label class="block uppercase tracking-wide text-gray-200 text-xs text-left font-bold mb-2" for="grid-first-name">
                          Email address
                        </label>
                        <input className="text-white outline-none text-md pl-4 py-3 rounded border w-full placeholder:text-neutral-400 pr-4 bg-white/[0.05] border-white/[0.09] hover:bg-white/[0.09] hover:border-white/[0.15] focus:bg-white/[0.09] focus:border-primary-500" type="email" placeholder="email@example.com" ref={emailRef}/>
                        <button class="block w-full mt-5 rounded bg-gradient-to-r from-[#1488CC] to-[#2B32B2] hover:bg-[#1488CC] hover:bg-none hover:border-primary-400 font-medium text-white py-[10px]" type="submit">
                            <svg className={`inline mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-white/[0.09] text-white/[0.09] dark:fill-gray-300 ${loading ? "block" : "hidden"}`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            Send
                        </button>
                    </form>
                    <p className='text-sm text-gray-400 mt-5'>
                        We will send you an email with the instructions to reset your password. Emails can take a few minutes to be sent, don't forget to check your spam folder!
                    </p>
                </div>}
                <div className='flex flex-row justify-center items-center mt-5'>
                    <Copyright/>
                </div>
            </div>
        </div>
        <div className=" md:flex hidden flex-col justify-center items-center h-screen w-2/3">
            <p className='text-2xl font-bold text-white'>Recover your Wolfer account</p>
            <p className='text-lg mt-5 text-white'>Lost your password? Don't worry, you are on the way to recover it.</p>
        </div>
    </div>
  )
}
