import React from 'react'
import Copyright from '../../components/Copyright'
import BetaDownloadCard from '../components/cards/DownloadCard'
import BetaReportBugsCard from '../components/cards/ReportBugsCard'
import BetaRewardsCards from '../components/cards/RewardsCard'
import BetaScheduleCard from '../components/cards/ScheduleCard'

export default function BetaConsole(props) {
  return (
    <div className='flex flex-col md:flex-row w-full h-full mt-5 gap-5'>
        <BetaScheduleCard status={props.status}/>
        <div className='flex flex-col w-full h-auto flex-wrap'>
          <div className='flex flex-row w-full h-auto gap-5 flex-wrap'>
            <BetaDownloadCard status={props.status}/>
            <BetaReportBugsCard status={props.status}/>
            <BetaRewardsCards status={props.status}/>
          </div>
          <div className='mt-5 md:mt-10 lg:mt-20'>
            <Copyright/>
          </div>
        </div>
    </div>
  )
}
