import React from 'react'
import { Link } from 'react-router-dom'

function RewardItem(props) {
    return (
        <div className='flex flex-row justify-between items-center p-2 w-full'>
            <div className={`w-7 h-7 flex flex-col items-center justify-center ${props.rewarded ? 'bg-green-500' : 'bg-red-500'} rounded-full`}>
                <p className='text-2xl font-bold text-white'><img src={`/images/${props.rewarded ? 'check' : 'x'}.svg`} width="20" alt="Icon"/></p>
            </div>
            <p className='text-white text w-5/6 text-lg font-medium'>{props.name}</p>
        </div>
    )
}

export default function BetaRewardsCards(props) {
  return (
    <div className='border-gray-600 bg-gray-700 border-[1px] h-auto w-full sm:w-1/2 md:w-1/3 lg:w-1/5 shadow-md rounded-md'>
        <div className='flex flex-col p-5 h-full justify-between'>
            <p className='text-2xl font-bold text-white'>Rewards status</p>
            <div className='flex flex-col justify-between items-center'>
                <RewardItem name="Beta tester Background" rewarded={props.status.rewards.background === 0 ? false : true}/>
                <RewardItem name="Beta tester Outfit" rewarded={props.status.rewards.outfit === 0 ? false : true}/>
                <RewardItem name="Beta tester Hat" rewarded={props.status.rewards.hat === 0 ? false : true}/>
                <RewardItem name="200 coins" rewarded={props.status.rewards.coins === 0 ? false : true}/>
                <RewardItem name="Discord role" rewarded={props.status.rewards.dcrole === 0 ? false : true}/>
            </div>
        </div>
    </div>
  )
}
