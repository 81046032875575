import React from 'react'

export default function StatsCard(props) {
    console.log(props.info)
    return (
        <div className='flex flex-col p-5'>
            <p className='text-2xl font-bold text-white'>Statistics</p>
            <div className='flex flex-row justify-center items-center mt-2'>
                <p className='text-white'>You currently have <span className='font-bold'>{props.info.coins}</span></p>
                <img src='https://assets.wolfer.fun/other/coins.png' className='ml-1 w-5 h-5 inline' alt="coin"/>
            </div>
            <div class='flex flex-row justify-center items-center mt-2'>
                <p className='text-white'>Level</p>
                <p className='font-bold text-2xl text-white ml-1'>{props.info.leveling.level}</p>
            </div>
            <div class='flex flex-row justify-center items-center'>
                <p className='font-bold text-lg text-white'>{props.info.leveling.xp}/100</p>
                <p className='text-white ml-1'>XP</p>
            </div>
        </div>
    )
}
