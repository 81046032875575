import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import AccountIndex from "./account/pages/Index";
import Login from "./auth/Login";
import Recover from "./auth/Recover";
import Register from "./auth/Register";
import BetaIndex from "./beta/pages/Index";
import Err404 from "./errors/404";
import Home from "./home/Home";
import PrivacyPolicy from "./legal/PrivacyPolicy";
import TermsAndConditions from "./legal/TermsAndConditions";
import Status from "./status/Status";

function App() {
  return (
    <div>
      <Router>
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/recover" element={<Recover/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/register" element={<Register/>}/>
            <Route path="/account" element={<AccountIndex/>}/>
            <Route path="/beta" element={<BetaIndex/>}/>
            <Route path="/tos" element={<TermsAndConditions/>}/>
            <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
            <Route path="/status" element={<Status/>}/>
            <Route path="*" element={<Err404/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
