import React from 'react'
import { useNavigate } from 'react-router-dom';
import BetaLoading from '../../beta/components/Loading';
import Copyright from '../../components/Copyright';
import Navbar from '../../components/Navbar'
import AvatarCard from './components/AvatarCard';
import BetaCard from './components/BetaCard';
import StatsCard from './components/StatsCard';
import TranslateCard from './components/TranslateCard';
import UserInfoCard from './components/UserInfoCard';

export default function AccountIndex() {
    const navigation = useNavigate();
    const [userInfo, setUserInfo] = React.useState({});

    async function populate() {
        const userInfo = await (await fetch(`https://api.wolfer.fun/user/info/${localStorage.getItem("uuid")}`)).json();
        if (userInfo.statusCode !== 200) {
            localStorage.removeItem('token');
            localStorage.removeItem('uuid');
            localStorage.removeItem('email');
            navigation('/login');
            return
        }
        setUserInfo(userInfo);
    }
    React.useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigation('/login');
        }
        document.title = 'Account - Wolfer';
        populate();
    }, [])
  return (
    <div>
        <Navbar/>
        <div className='flex flex-col w-full h-screen p-10 pt-[100px]'>
            <div className='flex flex-row w-full justify-between flex-wrap-reverse'>
                <p className='text-4xl font-bold text-white'>Your account</p>
                <button className='bg-red-500 text-white w-20 md:w-36 font-bold p-2 md:p-3 rounded-md mt-5 mb-2 md:mb-0' onClick={() => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('uuid');
                    localStorage.removeItem('email');
                    navigation('/login');
                }}>Logout</button>
            </div>
            {!userInfo.statusCode ? <BetaLoading/> : <div className='flex flex-col w-full h-full'>
            <div className='flex flex-col md:flex-row w-full h-full mt-5 gap-5'>
                <div className='border-gray-600 bg-gray-700 border-[1px] h-auto w-full sm:w-1/2 md:w-1/3 lg:w-1/5 shadow-md rounded-md'>
                    <UserInfoCard info={userInfo}/>
                </div> 
                <div className='flex flex-col w-full h-auto flex-wrap'>
                  <div className='flex flex-row w-full h-auto gap-5 flex-wrap'>
                    <div className='border-blue-600 bg-blue-700 border-[1px] h-auto w-full sm:w-1/2 md:w-1/3 lg:w-1/5 shadow-md rounded-md'>
                        <BetaCard/>
                    </div>
                    <div className='border-green-600 bg-green-700 border-[1px] h-auto w-full sm:w-1/2 md:w-1/3 lg:w-1/5 shadow-md rounded-md'>
                        <TranslateCard/>
                    </div>
                    <div className='border-gray-600 bg-gray-700 border-[1px] h-auto w-full sm:w-1/2 md:w-1/3 lg:w-1/5 shadow-md rounded-md'>
                        <AvatarCard info={userInfo}/>
                    </div>
                    <div className='border-gray-600 bg-gray-700 border-[1px] h-auto w-full sm:w-1/2 md:w-1/3 lg:w-1/5 shadow-md rounded-md'>
                        <StatsCard info={userInfo}/>
                    </div>
                  </div>
                  <div className='mt-5 md:mt-10 lg:mt-20'>
                    <Copyright/>
                  </div>
                </div>
                </div>
            </div> 
            }
        </div>
    </div>
  )
}
