import React from 'react'

export default function BetaNoBetaSlots() {
  return (
    <div className='flex flex-col w-full h-full justify-center items-center'>
        <img src="/images/hourglass.png" alt="Hourglass" width="100"/>
        <p className='text-4xl font-bold mt-2 text-white text-center w-full'>No beta slots available (yet)</p>
        <p className='text-white text-center w-full'>Your application is in queue, and will be reviewed if we need more beta testers.</p>
        <p className='text-white mt-3 text-center w-full'><img src="/images/envelope.svg" width="20" className='inline' alt="Envelope"/> You will be notified via email if this status updates.</p>
    </div> 
  )
}
